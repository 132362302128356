@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("./Pretendard-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
