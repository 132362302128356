// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}
.cus-wrapper {
  padding-top: 70px !important;
}
.container {
  max-width: 100% !important;
  overflow: auto !important;
}.pd-t-7 {
  padding-top: 7px !important;
}.pd-l-7 {
  padding-left: 7px !important;
}
.pd-10 {
  padding: 10px !important;
}
.pd-l-25 {
  padding-left: 25px;
}
.pd-l-40 {
  padding-left: 40px;
}
.pd-r-40 {
  padding-right: 40px;
}
.pd-l-0 {
  padding-left: 0px;
}
.pd-0 {
  padding: 0px !important;
}
.pd-r-0 {
  padding-right: 0px;
}
.mg-r-5 {
  margin-right: 5px;
}
.mg-r-10 {
  margin-right: 10px;
}
.mg-r-15 {
  margin-right: 15px;
}
.mg-r-20 {
  margin-right: 20px;
}
.mg-b-0 {
  margin-bottom: 0px;
}
.mg-b-5 {
  margin-bottom: 5px;
}
.mg-b-10 {
  margin-bottom: 10px;
}
.mg-b-15 {
  margin-bottom: 15px;
}
.mg-b-20 {
  margin-bottom: 20px;
}
.mg-t-5 {
  margin-top: 5px;
}
.mg-t-10 {
  margin-top: 10px;
}
.mg-t-15 {
  margin-top: 15px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-t-25 {
  margin-top: 25px;
}
.mg-t-28 {
  margin-top: 28px;
}.mg-l-2 {
  margin-left: 2px;
}
.mg-l-5 {
  margin-left: 5px;
}
.mg-l-10 {
  margin-left: 10px;
}
.mg-l-15 {
  margin-left: 15px;
}
.mg-l-20 {
  margin-left: 20px;
}
.d-webkit-box {
  display: -webkit-box !important;
}
.webkit-box-pack {
  -webkit-box-pack: center !important;
}
.p-0 {
  padding: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.drag-div-bd {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ebedf3;
  align-items: center;
  display: flex;
}
.border-div {
  border: 1px solid #ebedf3 !important;
}
.font-bold {
  font-weight: 600 !important;
}

.title-steps {
  margin-top: 15px;
  border: 1px solid #ebedf3;
  max-width: 640px;
  padding: 20px;
  min-height: 100px;
  width: 640px;
  text-align: left;
}
.invalid-feedback {
  display: block !important;
}
.td-function-groups {
  min-width: 540px;
  overflow-y: auto;
  overflow-x: hidden;
}
.wd-200 {
  width: 200px;
  min-width: 200px;
}
.wd-230 {
  width: 230px;
}

.wd-250 {
  width: 250px;
}
.wd-350 {
  width: 350px;
}
.wd-100 {
  width: 100px;
}
.wd-50 {
  width: 50px;
}
.wd-75 {
  width: 75px;
}
.wd-75 {
  width: 75px;
}.wd-140 {
  width: 140px;
}
.wd-150 {
  width: 150px;
  min-width: 150px
}
.max-800{
  max-width: 800px;
}

.wd-160 {
  width: 160px;
}

.ht-100 {
  height: 100px !important;
}
.mht-100 {
  max-height: 100px !important;
}
.ht-120 {
  height: 120px !important;
}
.ht-35 {
  height: 35px !important;
}
.ht-50 {
  height: 50px !important;
}
.ht-50 {
  height: 50px !important;
}
.wd-c-ing {
  width: 700px !important;
}
.wd-c-steps {
  width: 480px !important;
}
input:read-only {
  background-color: #f3f6f9 !important;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
.modal .modal-header .close span {
  display: block !important;
}
.modal-second {
  z-index: 1050 !important;
}
.td-detail {
  background-color: #ebedf37a;
  font-weight: 500;
}
.form-control[readonly] {
  background-color: #f3f6f9 !important;
}
.div-youtube {
  width: 640px;
  height: 390px;
}
.ht-38 {
  height: 38px !important;
}
.p-action {
  height: 120px !important;
  line-height: 120px;
  padding-left: 10px !important;
}
.bd-t {
  border-top: 1px solid #ebedf3;
}

.bd-b {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 0px !important;
  padding-bottom: 20px !important;
}

.card-body {
  padding: 20px !important;
}
.card-header {
  padding: 20px !important;
}
.card-footer {
  padding: 20px !important;
}
.bd-weekly {
  max-width: 160px;
  display: block;
  width: 100%;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  box-shadow: none;
  background-color: #f3f6f9;
  cursor: pointer;
}
.bd-weekly-count {
  border: 1px solid #e4e6ef;
  padding: 4px !important;
  margin-left: 10px;
  background-color: bisque;
}
.slick-arrow {
  background-color: blueviolet !important;
}
.bg-active {
  color: #ffffff !important;
  background-color: #3699ff !important;
  border-color: #3699ff !important;
}

.text-align-center {
  text-align: center;
}

.color-red {
  color: red;
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
}

.login-page {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 450px;
  }
}

.push-filter .react-datepicker-wrapper {
  margin-right: 20px;
}

.push-form .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 10;
}
.tiny-editor{
  position: relative;
}
.tiny-image{
  position: absolute;
  z-index: 10;
  margin-top: 3px;
  margin-left: 4px;
  background-color: white;
  width: 34px;
  height: 34px;
  svg{
    margin-top: 5px;
    margin-left: 5px;
  }
}
.tiny-image:hover{
  background: #dee0e2;
  border: 0;
  box-shadow: none;
  color: #222f3e;

}
.tiny-editor .tox .tox-editor-header {
  z-index: 0;
}

.loading {
  .modal-backdrop,
  .modal {
    z-index: 9999;
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 2 !important;
}

.dashboard {
  &__card-body {
    padding: 0px 20px 20px 20px;
  }
  .nav.nav-pills .nav-link.active {
    background-color: #50cd89;
  }
  .nav .nav-link.active:hover:not(.disabled) {
    color: #ffffff;
  }

  .nav .nav-link:hover:not(.disabled) {
    color: #50cd89;
  }

  .btn.btn-color-gray-400 {
    color: #b5b5c3;
  }
  .btn.btn-color-gray-400 i {
    color: #b5b5c3;
  }
  .btn.btn-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: #b5b5c3;
  }
  .btn.btn-active-primary.active .svg-icon svg [fill]:not(.permanent):not(g),
  .btn.btn-active-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
  .btn.btn-active-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: white;
  }
  .btn.btn-outline-default {
    border: 1px solid #e4e6ef;
  }

  .btn-check:active + .btn.btn-active-primary,
  .btn-check:checked + .btn.btn-active-primary,
  .btn.btn-active-primary.active,
  .btn.btn-active-primary.show,
  .btn.btn-active-primary:active:not(.btn-active),
  .btn.btn-active-primary:focus:not(.btn-active),
  .btn.btn-active-primary:hover:not(.btn-active),
  .show > .btn.btn-active-primary {
    color: #fff;
    border-color: #50cd89;
    background-color: #50cd89 !important;
  }

  a.text-hover-primary:hover,
  .text-hover-primary:hover {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
      box-shadow 0.15s ease;
    color: #50cd89 !important;
  }

  .card.card-custom {
    box-shadow: none;
  }
}

.btn.btn-success,
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-success:focus:not(.btn-text),
.btn.btn-success.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #5CA143;
  border-color: #5CA143;
}

.overflow-x {
  overflow-x: auto !important;
}
.overflow-y {
  overflow-y: auto !important;
}
.div-action-group {
  width: 100% !important;
  overflow-x: auto !important;
}
.table-head-custom {
  th {

    color: black !important;
    text-align: left;
  }
  td {
    text-align: left;

  }
}
.modal-custom {
  .modal-dialog {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .modal-content {
    width: calc(100vw - 50px) !important;
  }
}
.th-sub-category {
  background-color: #ddd1f7;
}
.th-admin-update {
  background-color: #defffe;
}
.td-ellipsis {
  display: block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-header-dnd{
  padding: 0 !important;
  min-height:20px !important;

}.pd-0 {
  padding: 0px !important;
}
.App {
  width: 100%;
}
.border-rounded{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.div-badge{
   top: 0!important;
   left: 55px!important;
}
.btn-close{
  border: none ;
  width: 20px;
  height: 20px;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
}

.login-form{
  max-width: 600px !important;
}

.selection-input-4{
  width: 17px;
  height: 17px;
}
.wmde-markdown{
  background-color: white !important;
  color: #3f4254 !important;

}
.div-product{
  background-color: hsl(0,0%,90%);
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    margin: 5px;
    min-width: 0;
    box-sizing: border-box;
    padding:5px ;
    cursor: pointer;
}
.div-product-2{
  background-color: hsl(0,0%,90%) !important;
    padding:5px !important;
    cursor: pointer;
}
.div-product-3{
  background-color: hsl(0,0%,90%) !important;
    cursor: pointer;
}
.div-product-3-deleted{
  background-color: hsl(0, 87%, 79%) !important;
  cursor: pointer;
}
.div-drag{
  border: 1px dashed gray;
    background-color: white;
    padding: 0.5rem 1rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    cursor: move;
    float: left;
    opacity: 1;
    width: 100%;
}
.dangerously-set-innerHTML{
  img{
    max-width: 347px;
    height: auto;
  }
}

.hide-scroll::-webkit-scrollbar{
  display: none;}


.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
	padding-left: 2.25rem;

  & .custom-control-label {
    padding-left: .75rem;
    padding-top: 0.15rem;

    &::before {
      border-radius: 1rem;
      height: 1.5rem;
      width: 2.5rem;
    }

    &::after {
			border-radius: .65rem;
      height: calc(1.5rem - 4px);
      width: calc(1.5rem - 4px);
    }
  }
	
	.custom-control-input:checked ~ .custom-control-label::after{
  transform: translateX(1rem);
	}
}
.react-datepicker__input-container{
  input{
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  input:focus{
    color: #3F4254;
    background-color: #ffffff;
    border-color: #69b3ff;
    outline: 0;
  }
}
.img-full-width{
  img{
    max-width: 100% !important;
    height: auto;
  }
}
.text-in-two-line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hide-scroll::-webkit-scrollbar{
  display: none;}


.react-datepicker-popper{
  z-index: 11!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}