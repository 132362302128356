.creator-detail {
    &.table-responsive {
        & table {
            border-collapse: collapse;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        tr {
            border: 1px solid $gray-200;
            min-height: 1.5rem;

            td {
                padding: 5px;
                border-right: 1px solid $gray-200;
                font-size: 14px;
                color: $gray-600;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        @media screen and (max-width: 767px) {

            .td-label,
            .text-left {
                display: block;
                width: 100%;
            }

            tr {
                display: block;
                margin-bottom: 10px;
            }

            td {
                display: block;

                &:first-child {
                    margin-top: 16px;
                }
            }
        }
    }

    .td-label {
        background-color: #CCC;
        font-weight: 400;
        color: #000 !important;
        max-width: 8rem;
        min-width: 8rem;
        text-align: left;
    }

    .td-label-sm {
        background-color: #CCC;
        font-weight: 400;
        color: #000 !important;
        max-width: 2rem;
        min-width: 2rem;
        text-align: left;
    }
}